/* eslint-disable max-len */
import { Config } from '@sigeo-registry/config-react';
//import ita from 'antd/lib/locale/it_IT';
import us from 'antd/lib/locale/en_US';
import * as Style from 'ol/style';

import type { Settings } from '@sigeo-registry/config-react';
import type { KeycloakInitOptions } from 'keycloak-js';
import type {
  /* QueryCache, MutationCache, */ DefaultOptions
} from 'react-query';
import { notification } from 'antd';

const config = new Config({
  app: {
    locale: us,
    theme: 'light',
    sidebar: {
      width: 210,
      collapsed: true
    }
  },

  notification: {
    duration: 3
    //seconds
  },

  websocket: {
    path: process.env.REACT_APP_WEBSOCKET_URL,
    keepAlive: {
      active: true,
      duration: 50000
      //milliseconds
    }
  },

  query: {
    // queryCache: QueryCache;
    // mutationCache: MutationCache;
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        suspense: true,
        useErrorBoundary: false,
        retry: false,
        staleTime: 10 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        onError: () => {
          const args = {
            message: 'ERROR',
            description: 'momentary server slowdown',
            duration: 3
          };
          notification.warning(args);
        }
      }
    } as DefaultOptions
  },

  globalLoader: { showSpinner: false, speed: 500, minimum: 0.5 },

  jobs: {
    DATE_UI_FORMAT: 'DD-MM-YYYY',
    MAX_DAY_SEARCH: 180
  },

  auth: {
    default: {
      onLoad: 'check-sso',
      enableLogging: false
    } as KeycloakInitOptions,

    development: {
      enableLogging: true
    } as KeycloakInitOptions
  },

  map: {
    default: {
      geoserverWMS: 'https://fwc-dev.bizio.dev/geoserver/fwc/wms',
      proj: 'EPSG:32633',
      extent: [-602202.7862, 4122884.6107, 829892.3674, 5319642.5989],
      center: [251535.08, 4654130.89],
      defaultPadding: [0, 0, 0, 0],
      markers: {
        '5G': 'https://cdn.mapmarker.io/api/v1/pin?size=40&background=%23D33115&text=5G&color=%23FFFFFF&voffset=0&hoffset=0&',
        generic:
          'https://cdn.mapmarker.io/api/v1/pin?size=40&background=%23D33115&icon=fa-circle&color=%23FFFFFF&voffset=0&hoffset=0&',
        point:
          'https://cdn.mapmarker.io/api/v1/fa?size=40&icon=fa-circle&color=%23D33115&',
        '5G_icon':
          'https://cdn.mapmarker.io/api/v1/fa?size=40&icon=fa-wifi&color=%23F44E3B&',
        building:
          'https://cdn.mapmarker.io/api/v1/pin?size=40&background=%23000080&text=B&color=%23FFFFFF&voffset=0&hoffset=0&',
        manufactured:
          'https://cdn.mapmarker.io/api/v1/pin?size=40&background=%23808000&text=M&color=%23FFFFFF&voffset=0&hoffset=0&'
      },

      antenne: {
        cluster: {
          distance: 30,
          zoomThreshold: 12
        },

        style: {
          good: new Style.Style({
            stroke: new Style.Stroke({
              width: 0,
              color: 'transparant'
            }),
            fill: new Style.Fill({
              color: 'rgba(255, 213, 145, .8)'
            })
          }),

          ok: new Style.Style({
            stroke: new Style.Stroke({
              color: 'transparant',
              width: 0
            }),
            fill: new Style.Fill({
              color: 'rgb(183, 235, 143, .8)'
            })
          }),

          bad: new Style.Style({
            stroke: new Style.Stroke({ width: 0, color: 'transparant' }),
            fill: new Style.Fill({
              color: 'rgb(255, 163, 158, .8)'
            })
          }),

          green: new Style.Style({
            fill: new Style.Fill({
              color: 'transparent'
            }),
            stroke: new Style.Stroke({
              width: 3,
              color: '#2eb82e'
            }),
          }),

          cluster: (feat) => {
            const style = new Style.Style({
              image: new Style.Circle({
                radius: 20,
                fill: new Style.Fill({
                  color: '#3399CC'
                })
              }),
              text: new Style.Text({
                text: `${feat.get('features').length}`,
                fill: new Style.Fill({
                  color: 'white'
                })
              })
            });

            return style;
          },

          clusterRed: (feat) => {
            const style = new Style.Style({
              image: new Style.Circle({
                radius: 20,
                fill: new Style.Fill({
                  color: '#ff0000'
                })
              }),
              text: new Style.Text({
                text: `${feat.get('features').length}`,
                fill: new Style.Fill({
                  color: 'white'
                })
              })
            });

            return style;
          }
        }
      }
    }
  }
} as unknown as Settings<any>);

export default config;
