import * as React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import useNetwork from './useNetwork';
import NetworkError from './NetworkError';

export type NetworkComponent = React.FC<any>;

const Network: NetworkComponent = ({ children }) => {
  const [isOnline] = useNetwork();
  const handleError = useErrorHandler();

  React.useEffect(() => {
    if (false === isOnline) {
      handleError(
        new NetworkError('There is a problem with your internet connection')
      );
    }
  }, [isOnline, handleError]);

  return children as React.ReactElement;
};

Network.whyDidYouRender = true;

export default Network;
