import React from 'react';
//import { Result, Button } from 'antd';
import { FallbackProps } from 'react-error-boundary';
//import { Link } from 'react-router-dom';

export type ErrorProps = FallbackProps

export type ErrorComponent = React.FC<ErrorProps>;

//const Error: ErrorComponent = ({ error, resetErrorBoundary }) => {
const Error: ErrorComponent = () => {
  history.back();
  return null; 
  {/* (
    <>
      {error.message === 'Request failed with status code 500' ? (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Link to="/">
              <Button>Try again</Button>
            </Link>
          }
        />
      ) : error.message === 'Request failed with status code 404' ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/">
              <Button onClick={() => resetErrorBoundary()}>Try again</Button>
            </Link>
          }
        />
      ) : (
        <Result
          status="error"
          title="Site Error"
          subTitle="Something went wrong."
          extra={
            <Link to="/">
              <Button onClick={() => resetErrorBoundary()}>Try again</Button>
            </Link>
          }
        ></Result>
      )}
    </> 
    
  );*/}
  
};

export default Error;
