import './__DEV__';
import * as React from 'react';
import { render } from 'react-dom';
import Root from './Root';
import './projections';
import * as serviceWorker from './serviceWorker';

import './style.css';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  require('./__DEV__').default;
}

render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
