import { QueryClient } from 'react-query';
import config from '../config';

const queryClient = new QueryClient(config.get('query'));

//NOTE: in dev attche the query client justt to quick check of cache
if (process.env.NODE_ENV === 'development') {
  (window as any).queryClient = queryClient;
}

export default queryClient;
