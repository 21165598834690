import ky, { HTTPError } from 'ky';
import config from '../config';

import type { SearchParams, ApiQueryObject } from '../types';

export type HttpClient = typeof ky;
export type HttpError = HTTPError;

const apiClient: HttpClient = ky.extend({
  prefixUrl: `${config.env('REACT_APP_API_URL')}`,
  retry: 0,
});

export default apiClient;

export const authHeaderToken = () => {
  const token = localStorage.getItem('authorization');

  if (token && token !== 'undefined') {
    return {
      Authorization: `Bearer ${  token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
     
    };
  } else {
    return {};
  }
};

export const authHeaderTokenAppJson = () => {
  const token = localStorage.getItem('authorization');

  if (token && token !== 'undefined') {
    return {
      Authorization: `Bearer ${  token}`,
      'Content-Type': 'application/json',
    };
  } else {
    return {};
  }
};

export const authHeaderTokenDownload = () => {
  const token = localStorage.getItem('authorization');

  if (token && token !== 'undefined') {
    return {
      Authorization: `Bearer ${ token }`,
      'Content-Type': 'application/octed-stream',
    };
  } else {
    return {};
  }
};

export const authHeaderTokenUpload = () => {
  const token = localStorage.getItem('authorization');

  if (token && token !== 'undefined') {
    return {
      Authorization: `Bearer ${ token }`,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    return {};
  }
};

export const createApiSearchQuery = (param: SearchParams): ApiQueryObject => {
  return (
    Object.entries(param)
      // first remove undefined and null values
      .reduce((a, [k, v]) => {
        /**
         * original rule, we apply some Moore stuff and convert to AND that is faster than OR
         * !(v === void 0 || v === null || String(v).length === 0)
         */
        if (v !== void 0 && v !== null && String(v).length !== 0) {
          a.push([k, v]);
        }
        return a;
      }, [])
      // attach $ to every valid key
      .reduce((a, [k, v]) => {
        if(k !== 'starred')
          a[`$${k}`] = v;
        else{
          a[`${k}`] = v;
        }
        return a;
      }, {})
  );
};

export const createApiSearchQueryFilter = (param: any) => {
  return (
    Object.entries(param)
      // first remove undefined and null values
      .reduce((a, [k, v]) => {
        /**
         * original rule, we apply some Moore stuff and convert to AND that is faster than OR
         * !(v === void 0 || v === null || String(v).length === 0)
         */
        if (v !== void 0 && v !== null && String(v).length !== 0) {
          a.push([k, v]);
        }

        return a;
      }, [])
      // attach $ to every valid key

      .reduce((a, [k, v], index, arr) => {
        a =
          `${a 
          }${k} ${
            typeof v === 'boolean' || typeof v === 'number' ? 'eq' : 'cn'
          } ${
            typeof v === 'boolean' || typeof v === 'number'
              ? v
              : String(v).toLocaleLowerCase()
          }${index + 1 < arr.length ? ' and ' : ''}`;
        return a;
      }, [])
  );
};

export const createApiSearchQueryFilterUpCase = (param: any) => {
  return (
    Object.entries(param)
      // first remove undefined and null values
      .reduce((a, [k, v]) => {
        /**
         * original rule, we apply some Moore stuff and convert to AND that is faster than OR
         * !(v === void 0 || v === null || String(v).length === 0)
         */
        if (v !== void 0 && v !== null && String(v).length !== 0) {
          a.push([k, v]);
        }

        return a;
      }, [])
      // attach $ to every valid key

      .reduce((a, [k, v], index, arr) => {
        a =
          `${a 
          }${k} ${
            typeof v === 'boolean' || typeof v === 'number' ? 'eq' : 'cn'
          } ${
            typeof v === 'boolean' || typeof v === 'number'
              ? v
              : String(v).toLocaleUpperCase()
          }${index + 1 < arr.length ? ' and ' : ''}`;
        return a;
      }, [])
  );
};
