import * as React from 'react';
import { onlineManager } from 'react-query';
import { useBoolean } from 'ahooks';

const useNetwork = () => {
  const [isOnline, { toggle }] = useBoolean(onlineManager.isOnline());

  React.useEffect(() => {
    const unsubscribe = onlineManager.subscribe(() => {
      toggle(onlineManager.isOnline());
    });

    return () => unsubscribe();
  }, [toggle]);

  return [isOnline];
};

export default useNetwork;
