import React from 'react';
import { Row, Col, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export interface CircleLoaderProps {
  style?: React.CSSProperties;
}

export type CircleLoaderComponent = React.FC<CircleLoaderProps>;

const CircleLoader: CircleLoaderComponent = ({ style, children }) => {
  const stl: React.CSSProperties = {
    fontSize: 60,
    color: '#1890ff',
    ...style,
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '50vh' }}
      className="loader circle-loader"
    >
      <Col>
        <Space direction="vertical" size="middle">
          <LoadingOutlined style={stl} />
          {children}
        </Space>
      </Col>
    </Row>
  );
};

export default CircleLoader;
