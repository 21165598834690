import React from 'react';
import { createPortal } from 'react-dom';
import ProgressManager from 'nprogress';
import 'nprogress/nprogress.css';

export interface GlobalLoaderProps {
  elemId?: string;
  config?: Record<string, unknown>;
}
export type GlobalLoaderComponent = React.FC<GlobalLoaderProps>;

interface ProgressProps {
  manager: typeof ProgressManager;
}

type ProgressComponent = React.FC<ProgressProps>;

const Progress: ProgressComponent = ({ manager }) => {
  React.useLayoutEffect(() => {
    manager.start();

    manager.inc(1);

    return () => {
      manager.done();
      //manager.remove();
    };
  });

  return null;
};

const GlobalLoader: GlobalLoaderComponent = ({ elemId = 'loader', config }) => {
  ProgressManager.configure({ ...config, parent: `#${elemId}` });

  const elem = document.getElementById(elemId);

  elem.style.cssText =
    'position: absolute; width: 100%; top: 0px; height: 8px;left:0';

  /* React.useLayoutEffect(() => {
    return () => {
      elem.style.cssText = '';
    };
  }, [elem]); */

  return createPortal(<Progress manager={ProgressManager} />, elem);
};

export default GlobalLoader;
