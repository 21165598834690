import type { LabeledValue } from 'antd/es/select';

export const isURL: (test: string) => boolean = (test: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return !!pattern.test(test);
};

export const getURLPathname = (url: string): string => {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url;
  }
};

export const stringNumbersToNumberArray = ({
  value,
  separator = ',',
}: {
  value: string;
  separator?: string;
}): number[] => value?.split(separator).map(Number);

export const createSelectOptions = <T = unknown>(
  data: T[],
  {
    value,
    label,
    key,
  }: {
    value: string | ((d: T) => string);
    label: string | ((d: T) => string);
    key?: string | ((d: T) => string);
  }
): LabeledValue[] => {
  return data?.map((c) => ({
    key: typeof key === 'function' ? key(c) : c[key],
    label: typeof label === 'function' ? label(c) : c[label],
    value: typeof value === 'function' ? value(c) : c[value],
  }));
};

export const makePercent =
  (accurancy: number) =>
    (dividend: number, divisor: number): number =>
      divisor === 0 ? divisor : +(dividend / divisor).toFixed(accurancy);

export const fourDigitPrecision = makePercent(4);
