import * as React from 'react';
import { ConfigProvider } from 'antd';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter as Router } from 'react-router-dom';
import { useQueryErrorResetBoundary, QueryClientProvider } from 'react-query';
import { ConfigProvider as AppSettingsProvider } from '@sigeo-registry/config-react';
import { ErrorBoundary } from 'react-error-boundary';
//import { ReactQueryDevtools } from 'react-query-devtools';

import config from './config';
import { auth, queryClient } from './services';
import { GlobalLoader, CirclelLoader } from './components/loader';
import { getURLPathname } from './helpers';
import { Network } from './components/network';
import Error from './components/errors/Error';

const App = React.lazy(() => import('./Main'));

type RootComponent = React.FC<any>;

const Root: RootComponent = () => {
  const basename: string = getURLPathname(process.env.PUBLIC_URL);
  const { reset } = useQueryErrorResetBoundary();

  return (
    <AppSettingsProvider config={config}>
      <ConfigProvider locale={config.get('app.locale')}>
        <ReactKeycloakProvider
          authClient={auth}
          initOptions={{ scope: process.env.REACT_APP_KEYCLOAK_SCOPE }}
          LoadingComponent={<CirclelLoader>init app...</CirclelLoader>}
          onTokens={(event) => {
            localStorage.setItem('authorization', event.token);
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Router basename={basename}>
              <ErrorBoundary onReset={reset} FallbackComponent={Error}>
                <React.Suspense
                  fallback={
                    <GlobalLoader config={config.get('globalLoader')} />
                  }
                >
                  <Network>
                    <App />
                  </Network>

                  {/* <ReactQueryDevtools initialIsOpen /> */}
                </React.Suspense>
              </ErrorBoundary>
            </Router>
          </QueryClientProvider>
        </ReactKeycloakProvider>
      </ConfigProvider>
    </AppSettingsProvider>
  );
};

Root.whyDidYouRender = true;

export default Root;
