import { PluggableMap } from 'ol';
import { Extent, containsExtent } from 'ol/extent';
import BaseLayer from 'ol/layer/Base';
import * as olLayer from 'ol/layer';
import { TileWMS } from 'ol/source';

export const getLayerExtent = (layer: BaseLayer): Extent => {
  return layer instanceof olLayer.Vector
    ? layer.getSource().getExtent()
    : layer.getExtent();
};

export const getMapExtent = (map: PluggableMap): Extent => {
  return map.getView().calculateExtent(map.getSize());
};

export const layerInExtent = (layer: BaseLayer, extent: Extent): boolean => {
  const layerExtent = getLayerExtent(layer);
  return layerExtent ? containsExtent(extent, layerExtent) : false;
};

export const isWMS = (layer): boolean => {
  return layer.getSource && layer.getSource() instanceof TileWMS;
};

export const isGroup = (layer): boolean => {
  return layer instanceof olLayer.Group;
};
